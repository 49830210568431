'use client'

import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Eye, EyeOff, TextSearch } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { ITERATION_COMMANDS } from '@/const/const'
import { cn } from '@/lib/utils'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { sendUserCommand } from '@/lib/sendUserCommand'

// Define the schema for a single request
const requestSchema = z.object({
  request: z.string(),
  type: z.enum(['text', 'secret']),
  user_value: z.string(),
})

// Define the schema for the entire form
const formSchema = z.object({
  id: z.string(),
  command: z.literal('INPUT_REQUEST'),
  args: z.object({
    requests: z.array(requestSchema),
  }),
  active: z.boolean(),
})

type FormData = z.infer<typeof formSchema>

export function UserInteractionForm({
  initialData,
  iterationId,
  className,
}: {
  initialData: FormData
  iterationId: string
  className?: string
}) {
  const [showPasswords, setShowPasswords] = useState<{ [key: number]: boolean }>({})
  const [isWorking, setIsWorking] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { toast } = useToast()

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData,
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      setIsWorking(true)
      try {
        const payload = {
          ...data,
          commandArgs: { ...data.args, request_id: initialData.id },
          command: ITERATION_COMMANDS.INPUT_ANSWER,
          iterationId,
        }

        await sendUserCommand(iterationId, payload)
        // analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
        //   iterationId: iterationId,
        //   prompt: prompt,
        // })
        setShouldHide(true)
      } catch (error) {
        console.error('Error creating an answer', error)
        toast({
          variant: 'destructive',
          title: 'Error creating an answer 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      } finally {
        setIsWorking(false)
      }
    },
    [toast, initialData]
  )

  const togglePasswordVisibility = (index: number) => {
    setShowPasswords(prev => ({ ...prev, [index]: !prev[index] }))
  }

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
    },
    [setIsOpen]
  )

  if (shouldHide) {
    return null
  }

  return (
    <div
      className={cn(
        'cursor-pointer overflow-hidden rounded-lg bg-white shadow outline outline-1 -outline-offset-1 outline-border',
        className
      )}
    >
      <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
        <CollapsibleTrigger asChild>
          <div className="inline-flex h-[72px] w-full items-center justify-between rounded-lg border border-border bg-white py-1 pl-1 pr-6 shadow-sm">
            <div className="flex items-center justify-start gap-6">
              <div className="flex size-16 items-center justify-center rounded-md bg-[#f9f1f8] p-[22px]">
                <div className="relative flex size-5 flex-col items-start justify-start">
                  <TextSearch className="text-plum-500" />
                </div>
              </div>
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                🙋 We have some questions. Answer them to continue.
              </div>
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="bg-white p-8">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4 space-y-8">
                {initialData.args.requests.map((request, index) => (
                  <FormField
                    key={index}
                    control={form.control}
                    name={`args.requests.${index}.user_value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{request.request}</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Input
                              {...field}
                              type={
                                request.type === 'secret' && !showPasswords[index]
                                  ? 'password'
                                  : 'text'
                              }
                              placeholder={request.user_value}
                            />
                            {request.type === 'secret' && (
                              <Button
                                type="button"
                                variant="ghost"
                                size="icon"
                                className="absolute right-2 top-1/2 -translate-y-1/2"
                                onClick={() => togglePasswordVisibility(index)}
                              >
                                {showPasswords[index] ? (
                                  <EyeOff className="size-4" />
                                ) : (
                                  <Eye className="size-4" />
                                )}
                                <span className="sr-only">
                                  {showPasswords[index] ? 'Hide password' : 'Show password'}
                                </span>
                              </Button>
                            )}
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
                <Button className="w-full" type="submit" disabled={isWorking}>
                  {isWorking ? 'Submitting...' : 'Submit'}
                </Button>
              </form>
            </Form>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}
