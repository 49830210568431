import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/catalyst/input.jsx'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const'

import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { cn } from '@/lib/utils'
import { MoveLeft, Plus, SquareArrowRight } from 'lucide-react'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore'
import { Textarea } from '@/components/ui/textarea'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import colors from 'tailwindcss/colors'
import Logo from '@/assets/svg-components/Logo.jsx'
import { useSendUserCommand } from '@/lib/sendUserCommand'
import SpecificationPreview from './SpecificationPreview.tsx'

type Extension = {
  id: string
  title: string
  prompt: string
  placeholder: string
  blueprintId?: string
}

type CustomExtensionOption = { type: 'custom' }
type BaseExtensionOption = { type: 'extension'; extension: Extension }

type ExtensionOption = BaseExtensionOption | CustomExtensionOption

export default function MContinuationPrompt({
  iterationId,
  className,
  iterationUsecaseId,
  command,
  specifications,
}: {
  iterationId: string
  className: string | undefined
  iterationUsecaseId: string
  command: (typeof ITERATION_COMMANDS)[keyof typeof ITERATION_COMMANDS]
  specifications: string | null
}) {
  const { getConfigurationTemplateByUsecaseId } = useStore()
  const configurationTemplate = getConfigurationTemplateByUsecaseId(iterationUsecaseId)
  const extensions = configurationTemplate?.iterationDefaultsTemplate?.usecase?.extensions
  const [selectedOption, setSelectedOption] = useState<ExtensionOption | null>(() => {
    if (!extensions || extensions?.length === 0) {
      return { type: 'custom' }
    }
    return null
  })

  const [isExtending, setIsExtending] = useState(false)
  const isUserSuperAdmin = useIsUserSuperAdmin()
  const [isOpen, setIsOpen] = useState(false)
  const sendCommand = useSendUserCommand(iterationId)

  const isSingleCustomOption = useMemo(() => {
    return !extensions || extensions.length === 0
  }, [extensions])

  const { toast } = useToast()

  const handleCustomClick = useCallback(() => {
    setSelectedOption({ type: 'custom' })
  }, [])

  const handleExtensionOptionClick = useCallback(extension => {
    setSelectedOption({ type: 'extension', extension })
  }, [])

  const handleBack = useCallback(() => {
    setSelectedOption(null)
    if (!extensions || extensions.length === 0) {
      setIsOpen(false)
    }
  }, [extensions])

  const sendExtendCommand = useCallback(
    (prompt: string, blueprintId: string) => {
      sendCommand.mutate(
        {
          command: ITERATION_COMMANDS.EXTEND,
          commandArgs: {
            prompt,
            iteration_id: iterationId,
            blueprint_id: blueprintId,
          },
        },
        {
          onError(error) {
            console.error('Error creating continuation prompt', error)
            toast({
              variant: 'destructive',
              title: 'Error extending iteration 😔',
              description: 'Try refreshing the page or contact Proofs team.',
            })
          },
          onSuccess() {
            analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
              iterationId: iterationId,
              prompt: prompt,
            })
            setIsExtending(true)
          },
        }
      )
    },
    [toast, sendCommand, iterationId]
  )

  const handleSubmit = useCallback(
    async ({ prompt, blueprintId }) => {
      if (!prompt || prompt?.length === 0 || !iterationId) {
        toast({
          variant: 'destructive',
          title: 'We need instructions!',
          description: 'Please provide instructions on how to continue',
        })
        return
      }
      sendExtendCommand(prompt, blueprintId)
    },
    [iterationId, sendExtendCommand, toast]
  )

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (open && isSingleCustomOption) {
        setSelectedOption({ type: 'custom' })
      }
    },
    [extensions]
  )

  if (isExtending || sendCommand.isPending || command === ITERATION_COMMANDS.EXTEND) {
    return (
      <div className="inline-flex h-[72px] w-full items-center justify-between rounded-lg border border-stone-200 bg-white py-1 pl-1 pr-6 shadow">
        <div className="inline-flex h-16 items-center justify-start gap-6">
          <div className="flex size-16 items-center justify-center rounded-md bg-[#f8f3fe] p-5">
            <div className="relative flex size-6 flex-col items-center justify-center">
              <Logo key="iteration-status-running-icon" color={colors.violet[500]} />
            </div>
          </div>
          <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
            Working on a solution
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'cursor-pointer overflow-hidden rounded-lg bg-white outline outline-1 -outline-offset-1 outline-border',
        !isOpen && 'shadow-sm',
        className
      )}
    >
      <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
        <CollapsibleTrigger asChild>
          <div
            className={cn(
              'inline-flex w-full items-center justify-between rounded-lg border border-border bg-white p-1 pr-6'
            )}
          >
            <div className="flex items-center justify-start gap-6">
              <div className="flex size-16 items-center justify-center rounded-md bg-[#f9f1f8] p-[22px]">
                <div className="relative flex size-5 flex-col items-center justify-center">
                  <SquareArrowRight className="text-plum-500" />
                </div>
              </div>
              <div className="text-base font-medium leading-normal text-stone-900">
                Continue project
              </div>
            </div>
            <div className="text-sm text-muted-foreground">
              Follow-up with blueprint or a custom request
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          {selectedOption ? (
            <PromptEditor
              option={selectedOption}
              isSuperAdmin={isUserSuperAdmin}
              onBack={handleBack}
              onSubmit={handleSubmit}
              isSingleCustomOption={isSingleCustomOption}
            />
          ) : (
            <div className="flex w-full flex-wrap items-center justify-start gap-1 p-2">
              {extensions?.map(extension => (
                <FollopUpOption
                  key={extension.id}
                  Icon={Plus}
                  label={extension.title}
                  onClick={() => handleExtensionOptionClick(extension)}
                />
              ))}
              <FollopUpOption Icon={Plus} label="Custom" onClick={handleCustomClick} />
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

function FollopUpOption({
  label,
  onClick,
  Icon,
}: {
  label?: string
  onClick?: () => void
  Icon: React.FC<{ className?: string }>
}) {
  return (
    <Button variant="secondary" className="bg-stone-200" onClick={onClick}>
      <div className="inline-flex h-10 w-full items-center justify-center gap-2 rounded-md  px-4 py-2">
        {Icon && <Icon className="size-4" />}
        <div className="text-sm font-medium leading-tight text-stone-900">{label}</div>
      </div>
    </Button>
  )
}

const CUSTOM_OPTION_PLACEHOLDER = 'Instructions on what to do next, please be specific'

function isExtensionWithBlueprintId(option: ExtensionOption): option is BaseExtensionOption {
  return option.type === 'extension' && Boolean(option.extension.blueprintId)
}

function isExtensionWithoutBlueprintId(option: ExtensionOption): option is BaseExtensionOption {
  return option.type === 'extension' && !option.extension.blueprintId
}

function isCustomOption(option: ExtensionOption): option is CustomExtensionOption {
  return option.type === 'custom'
}

function getBlueprintIdFromOption(option: ExtensionOption): string | null {
  return isExtensionWithBlueprintId(option) ? option.extension.blueprintId : null
}

function PromptEditor({
  option,
  onBack,
  onSubmit,
  isSuperAdmin = false,
  isSingleCustomOption = false,
}: {
  option: ExtensionOption
  onBack: () => void
  onSubmit: (props: { prompt: string; blueprintId: string }) => void
  isSuperAdmin?: boolean
  isSingleCustomOption?: boolean
}) {
  const [prompt, setPrompt] = useState<string>(() => {
    if (isExtensionWithoutBlueprintId(option)) {
      return option.extension.prompt
    } else {
      return ''
    }
  })
  const [blueprintId, setBlueprintId] = useState<string | null>(() => {
    return getBlueprintIdFromOption(option) ?? ''
  })
  const existingPrompt = option.type === 'extension' ? option.extension.prompt : ''
  const isSubmitDisabled = option.type === 'custom' ? prompt === '' : false
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      const length = textareaRef.current.value.length
      textareaRef.current.setSelectionRange(length, length)
    }
  }, [])

  const handleOnSubmit = useCallback(() => {
    if (isExtensionWithBlueprintId(option)) {
      onSubmit({ prompt: `${existingPrompt}\n\n${prompt}`, blueprintId })
    } else if (isExtensionWithoutBlueprintId(option) || isCustomOption(option)) {
      onSubmit({ prompt, blueprintId })
    }
  }, [option, prompt, blueprintId, onSubmit, existingPrompt])

  return (
    <div className="inline-flex  w-full flex-col items-start justify-start bg-white">
      {!isSingleCustomOption && (
        <div className="flex  flex-col items-start justify-start gap-2 self-stretch border-b border-stone-200 bg-stone-100 px-6 py-4">
          <div className="inline-flex items-center justify-start gap-2 py-0.5">
            <div className="flex size-4 items-center justify-center p-0.5">
              <Plus />
            </div>
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              {option.type === 'extension' ? option.extension.title : 'Custom'}
            </div>
          </div>
          {isExtensionWithBlueprintId(option) && (
            <div className="self-stretch whitespace-pre-wrap font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {existingPrompt}
            </div>
          )}
        </div>
      )}

      <div className="inline-flex  items-center justify-center gap-2 self-stretch px-6 py-5">
        <div className="flex w-full flex-col">
          {isSuperAdmin && (
            <Input
              placeholder="Blueprint ID (optional)"
              value={blueprintId}
              onChange={e => setBlueprintId(e.target.value)}
            />
          )}
          <Textarea
            ref={textareaRef}
            variant="ghost"
            resize={false}
            value={prompt}
            rows={3}
            onChange={e => setPrompt(e.target.value)}
            placeholder={
              option.type === 'extension' ? option.extension.placeholder : CUSTOM_OPTION_PLACEHOLDER
            }
          />
        </div>
      </div>
      <div className="flex w-full gap-2 px-6 pb-5">
        <Button size="icon" variant="outline" className="size-10" onClick={onBack}>
          <MoveLeft className="size-4" />
        </Button>
        <Button
          disabled={isSubmitDisabled}
          className="w-full tracking-tight"
          size="lg"
          onClick={handleOnSubmit}
        >
          <span>Request</span>
          <SquareArrowRight className="ml-2 size-4" />
        </Button>
      </div>
    </div>
  )
}
