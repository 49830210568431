import { useMemo, useState, Fragment } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { SlashIcon } from '@radix-ui/react-icons'
import PropTypes from 'prop-types'
import { GUNSLINGER_JOB_STATUSES, ITERATION_STATUSES } from '@/const/const'
import {
  CheckCheck,
  GitCompare,
  Loader,
  MoreVertical,
  PauseCircle,
  PlaneTakeoff,
  SquareActivity,
  SquareCode,
  TimerReset,
  TvMinimal,
  XOctagon,
} from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { SiGithub } from '@icons-pack/react-simple-icons'
import useStore from '@/stores/useStore.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { Link } from '@/components/catalyst/link.jsx'
import {
  convertGitHubSSHToHTTPS,
  getPreviewUrl,
  hasValidBranch,
  hasValidCommitHashes,
} from '@/lib/git-utils.js'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors'
import { formatIterationDate } from '@/lib/date-utils.js'
import { Button } from '@/components/ui/button'
import MCreateIterationDialog from '@/components/molecules/iteration-details/CreateIteration/MCreateIterationDialog.jsx'
import { stackTemplate } from '@/const/stackTemplates.ts'
import { formatIterationStatus, isVmDead, isVMreadyForPreview } from '@/lib/iteration-utils.ts'

export default function IterationHeader({
  organization,
  project,
  team,
  iterations,
  activeIterationId,
  onActiveSelected = iterationId => {
    console.log('Selected iteration', iterationId)
  },
  isLoading = false,
  onIterationCreated = () => {
    console.log('Default onIterationCreated function')
  },
}) {
  const breadcrumbs = useMemo(() => {
    return [
      {
        name: organization?.name || 'Organization',
        href: `/organizations/${project?.organizationId}`,
      },
      {
        name: team?.name || 'Team',
        href: `/teams/${project?.teamId}`,
      },
      {
        name: project?.name || 'Project',
        current: true,
      },
    ]
  }, [organization, team, project])

  const [isCreateIterationDialogOpen, setIsCreateIterationDialogOpen] = useState(false)

  const selectedIteration = useMemo(() => {
    return iterations?.find(iteration => iteration?.id === activeIterationId)
  }, [activeIterationId, iterations])

  const activeIterationStatus = useStore(state => state.activeIterationStatus)

  const [iterationStatus, statusColor, statusIcon] = useMemo(() => {
    switch (activeIterationStatus) {
      case ITERATION_STATUSES.AWAITING:
        return [
          'Awaiting',
          'zinc-500',
          <PlaneTakeoff key="iteration-status-awaiting-icon" className="text-zinc-500" />,
        ]
      case ITERATION_STATUSES.RUNNING:
        return [
          'Running',
          'violet-500',
          <Logo key="iteration-status-running-icon" color={colors.violet[500]} />,
        ]
      case ITERATION_STATUSES.PAUSED:
        return [
          'Paused',
          'zinc-500',
          <PauseCircle key="iteration-status-paused-icon" className="text-zinc-500" />,
        ]
      case ITERATION_STATUSES.DONE:
        return [
          'Done',
          'green-500',
          <CheckCheck key="iteration-status-done-icon" className="text-green-500" />,
        ]
      case ITERATION_STATUSES.FAILED:
        return [
          'Failed',
          'red-500',
          <XOctagon key="iteration-status-failed-icon" className="h-6 w-5 text-red-500" />,
        ]
      case ITERATION_STATUSES.RUN_REQUESTED:
        return [
          'Requested',
          'zinc-500',
          <TimerReset key="iteration-status-requested-icon" className="text-zinc-500" />,
        ]
      default:
        return [
          formatIterationStatus(activeIterationStatus),
          'zinc-500',
          <Logo key="iteration-status-running-icon" color={colors.zinc[500]} />,
        ]
    }
  }, [activeIterationStatus])

  const stack =
    selectedIteration?.configuration?.techStack ?? selectedIteration?.configuration?.technologies
  const TechnologyIcon = stackTemplate[stack]?.icon

  const previewUrl = getPreviewUrl(selectedIteration?.preview_urls)

  const projectName = `${project?.name ?? selectedIteration?.configuration?.prospectName ?? 'Current project'}`
  const hasRepo = Boolean(selectedIteration?.repoURI && hasValidBranch(selectedIteration))

  const showCodeChangesForIteration = hasValidCommitHashes(selectedIteration)

  return (
    <>
      <div className="inline-flex h-[314px] w-full flex-col items-start justify-start gap-8 rounded-lg border border-stone-200 bg-white pb-8 shadow ">
        <div
          className={`inline-flex items-center justify-between self-stretch rounded-t-lg border-b  border-stone-200 bg-gradient-to-r from-transparent via-transparent to-${statusColor}/20 px-8 py-4`}
        >
          <div className="flex items-center justify-start gap-0.5">
            <Breadcrumb>
              <BreadcrumbList className="font-['Inter'] text-xs font-normal leading-none text-stone-500">
                {breadcrumbs.map((breadcrumb, index) => {
                  if (breadcrumb.current) {
                    return (
                      <BreadcrumbItem key={index}>
                        <BreadcrumbLink className="text-stone-900">
                          {breadcrumb?.name ?? '...'}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    )
                  } else {
                    return (
                      <Fragment key={index}>
                        <BreadcrumbItem>
                          <BreadcrumbLink>{breadcrumb?.name ?? '...'} </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                          <SlashIcon />
                        </BreadcrumbSeparator>
                      </Fragment>
                    )
                  }
                })}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div className="flex items-center justify-start gap-3 bg-transparent">
            <div
              className={`font-pp-supply-sans text-xs font-normal uppercase leading-none text-${statusColor} bg-transparent`}
            >
              {iterationStatus}
            </div>
            <div className="inline-flex size-5 flex-col items-center justify-center">
              {statusIcon}
            </div>
          </div>
        </div>

        <div className="inline-flex items-center justify-between self-stretch px-8">
          <div className="flex items-center justify-start gap-3">
            <div className="font-pp-supply-sans text-3xl font-normal leading-10 text-stone-900">
              {projectName}
            </div>
          </div>
          <div className="flex items-center justify-start gap-2">
            <div className="flex size-10 items-center justify-center gap-2 rounded-md bg-white/0  ">
              {showCodeChangesForIteration && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="size-8 p-0">
                      <MoreVertical className="size-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    {showCodeChangesForIteration && (
                      <DropdownMenuItem>
                        <Link
                          href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/compare/${selectedIteration?.startCommitHash}...${selectedIteration?.endCommitHash}`}
                          target={'_blank'}
                          className="flex items-center"
                          onClick={() => {
                            analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES, {
                              iterationId: selectedIteration?.id,
                              projectId: selectedIteration?.projectId,
                              teamId: selectedIteration?.teamId,
                              organizationId: selectedIteration?.organizationId,
                              status: selectedIteration?.status,
                            })
                          }}
                        >
                          <GitCompare className="mr-2 size-4" />
                          <span>Code changes</span>
                        </Link>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="flex h-[122px] flex-col items-start justify-start gap-4 self-stretch px-8">
          <div className="inline-flex items-center justify-start gap-2 self-stretch">
            <div className="w-66 inline-flex flex-col items-start justify-start gap-1.5 rounded-md border border-stone-200 bg-white px-4 py-3">
              <div className="inline-flex items-center justify-between self-stretch text-stone-500 ">
                <div className=" font-['Inter'] text-xs font-normal leading-none  ">
                  Last activity
                </div>
                <SquareActivity className="relative size-4 " />
              </div>
              <div className="self-stretch font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                {formatIterationDate(selectedIteration?.updatedAt, 'unknown')}
              </div>
            </div>
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5 rounded-md border border-stone-200 bg-white px-4 py-3">
              <div className="inline-flex items-center justify-between self-stretch text-stone-500">
                <div className="font-['Inter'] text-xs font-normal leading-none ">Technology</div>
                <SquareCode className="relative size-4" />
              </div>
              <div className="inline-flex items-start justify-start gap-3">
                <div className="px flex size-4 items-center justify-center">
                  {TechnologyIcon && <TechnologyIcon />}
                </div>
                <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                  {stackTemplate[stack]?.label ?? ''}
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 self-stretch">
            <Button variant="secondary" asChild>
              {hasRepo ? (
                <Link
                  href={`${convertGitHubSSHToHTTPS(selectedIteration?.repoURI)}/tree/${selectedIteration?.branchName}`}
                  target={'_blank'}
                  onClick={() => {
                    analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO, {
                      iterationId: selectedIteration?.id,
                      projectId: selectedIteration?.projectId,
                      teamId: selectedIteration?.teamId,
                      organizationId: selectedIteration?.organizationId,
                      status: selectedIteration?.status,
                    })
                  }}
                >
                  <SiGithub />
                  <span>Repository</span>
                </Link>
              ) : (
                <Button disabled>
                  <Loader className="animate-spin" />
                  <span>Repository</span>
                </Button>
              )}
            </Button>

            <Button asChild>
              {previewUrl && isVMreadyForPreview(selectedIteration?.vmStatus) ? (
                <Link
                  href={previewUrl}
                  target={'_blank'}
                  onClick={() => {
                    analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW, {
                      iterationId: selectedIteration?.id,
                      projectId: selectedIteration?.projectId,
                      teamId: selectedIteration?.teamId,
                      organizationId: selectedIteration?.organizationId,
                      status: selectedIteration?.status,
                      vmStatus: selectedIteration?.vmStatus,
                    })
                  }}
                >
                  <TvMinimal />
                  <span>Preview</span>
                </Link>
              ) : (
                <Button disabled>
                  {isVmDead(selectedIteration?.vmStatus) ? (
                    <span>Preview lifespan has expired</span>
                  ) : (
                    <>
                      <Loader className="relative size-4 animate-spin" />
                      <span>Preview</span>
                    </>
                  )}
                </Button>
              )}
            </Button>
          </div>
        </div>
      </div>
      {selectedIteration?.projectId && isCreateIterationDialogOpen && (
        <MCreateIterationDialog
          key={selectedIteration?.id + '-create-iteration-dialog-beta'}
          projectId={selectedIteration?.projectId}
          isOpen={isCreateIterationDialogOpen}
          onClose={() => {
            setIsCreateIterationDialogOpen(false)
          }}
          sourceIterationData={selectedIteration || {}}
          isFirstIterationForProject={false}
          onIterationCreated={onIterationCreated}
        />
      )}
    </>
  )
}

IterationHeader.propTypes = {
  iterations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      prompt: PropTypes.string,
      status: PropTypes.string,
      updatedAt: PropTypes.shape({
        toDate: PropTypes.func,
      }),
    })
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  activeIterationId: PropTypes.string,
  onActiveSelected: PropTypes.func,
  lastTick: PropTypes.number,
  onIterationCreated: PropTypes.func,
}
