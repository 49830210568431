import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { FoldVertical, UnfoldVertical, Copy } from 'lucide-react'
import { useClipboardCopy } from '@/lib/hooks.js'
import { useToast } from '@/components/ui/use-toast.js'
import { Button } from '@/components/ui/button'
import { getMaxHeightClass } from '@/lib/dynamic-classes.ts'
import OCodeEditor from '@/components/organisms/OCodeEditor.jsx'

const COLLAPSED_HEIGHT_REM = 6

export default function AActionContentTerminal({
  content,
  terminalOutputs,
  errored = false,
  collapsedHeightRem = COLLAPSED_HEIGHT_REM,
  headerRightText,
  language,
}) {
  const [showTerminalOutputs, setShowTerminalOutputs] = useState(false)
  const [shouldShowExpandButton, setShouldShowExpandButton] = useState(false)
  const terminalContentRef = useRef(null)
  const { copy: copyContent } = useClipboardCopy()
  const { toast } = useToast()
  const contentRef = useRef(null)
  const buttonRef = useRef(null)
  const topRef = useRef(null)
  const [isEditorReady, setIsEditorReady] = useState(false)

  const isInView = element => {
    if (!element) return false
    // Get distance from element to top of viewport
    const rect = element.getBoundingClientRect()
    // Simple check if it's in the viewport
    return rect.top >= 0 && rect.top <= window.innerHeight
  }

  const className = classNames(
    "p-2 rounded-t-sm mt-1 break-all bg-stone-800 group relative px-4 text-sm font-normal font-['DM Mono'] leading-tight flex justify-between items-center",
    terminalOutputs && 'cursor-pointer ',
    !terminalOutputs && 'rounded-b-sm',
    errored && 'bg-red-700 text-zinc-200',
    !errored && 'bg-black text-green-400'
  )

  const handleCopyToClipboard = content => {
    copyContent(
      content,
      () =>
        toast({
          title: 'Copied to clipboard! 🎉',
        }),
      () =>
        toast({
          variant: 'destructive',
          title: 'Failed to copy to clipboard 😔',
        })
    )
  }

  let terminalOutputContent = ''
  if (!terminalOutputs) {
    terminalOutputContent = null
  } else if (typeof terminalOutputs === 'string') {
    terminalOutputContent = terminalOutputs
  } else {
    terminalOutputs?.forEach(content => {
      terminalOutputContent += `${content.replace('- stdout:', '').replace('- stderr:', '').replace('- exit_code: 0', '').trimLeft()}`
    })
  }

  const isJsonContent = useMemo(() => {
    if (!terminalOutputContent) return false
    try {
      JSON.parse(terminalOutputContent)
      return true
    } catch (e) {
      return false
    }
  }, [terminalOutputContent])

  const handleEditorDidMount = useCallback(() => {
    setIsEditorReady(true)
  }, [])

  useEffect(() => {
    if (terminalContentRef.current && (!isJsonContent || isEditorReady)) {
      const timeoutId = setTimeout(() => {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
        const maxCollapsedHeight = collapsedHeightRem * rootFontSize
        const contentHeight = terminalContentRef.current.scrollHeight
        setShouldShowExpandButton(contentHeight > maxCollapsedHeight)
      }, 100)

      return () => clearTimeout(timeoutId)
    }
  }, [terminalOutputContent, isEditorReady, collapsedHeightRem, isJsonContent])

  const handleToggle = () => {
    setShowTerminalOutputs(prev => !prev)

    requestAnimationFrame(() => {
      if (!showTerminalOutputs && !isInView(buttonRef.current)) {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else if (showTerminalOutputs && !isInView(topRef.current)) {
        topRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  return (
    <div>
      <div ref={topRef} /> {/* Marker for the top */}
      <div ref={contentRef} className={className}>
        <span className="mr-4 flex-1 whitespace-pre-wrap">{content}</span>
        {headerRightText && (
          <div className="font-['DM Mono'] mr-8 text-sm font-normal leading-tight text-green-400">
            {headerRightText}
          </div>
        )}

        <div
          className="absolute right-3 top-3 z-10 cursor-pointer opacity-0 group-hover:opacity-100"
          onClick={() => handleCopyToClipboard(content)}
        >
          <Copy className="size-4 text-gray-500 hover:text-green-400" />
        </div>
      </div>
      {!!terminalOutputContent && (
        <div className="group relative flex w-full flex-col gap-2">
          <div
            className="absolute right-3 top-3 z-10 cursor-pointer opacity-0 group-hover:opacity-100"
            onClick={() => handleCopyToClipboard(terminalOutputContent)}
          >
            <Copy className="size-4 text-gray-500 hover:text-green-400" />
          </div>
          <div
            ref={terminalContentRef}
            className={`font-['DM Mono'] flex w-full max-w-full flex-col whitespace-pre-wrap break-all rounded-b-sm bg-black text-sm font-normal leading-tight text-neutral-300 ${
              !showTerminalOutputs && `${getMaxHeightClass(collapsedHeightRem)} overflow-hidden`
            }`}
          >
            {isJsonContent || language ? (
              <OCodeEditor
                code={terminalOutputContent}
                language={language ?? 'json'}
                editable={false}
                onEditorDidMount={handleEditorDidMount}
              />
            ) : (
              <div className="p-4">{terminalOutputContent}</div>
            )}
          </div>
          {shouldShowExpandButton && (
            <Button
              ref={buttonRef}
              className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-100 px-3 py-1.5 text-stone-900"
              variant="secondary"
              onClick={handleToggle}
            >
              {showTerminalOutputs ? (
                <FoldVertical className="relative size-4" />
              ) : (
                <UnfoldVertical className="relative size-4" />
              )}

              <div className="font-['Inter'] text-sm font-medium leading-tight ">
                {showTerminalOutputs ? 'Collapse Output' : 'Expand Output'}
              </div>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

AActionContentTerminal.propTypes = {
  content: PropTypes.string,
  terminalOutputs: PropTypes.arrayOf(PropTypes.string),
}
