import { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { EnvironmentField } from './types.ts'
import { useMemo } from 'react'

export function useAnalystEnvFields(): EnvironmentField[] {
  const isSuperAdmin = useIsUserSuperAdmin()

  return useMemo(
    () =>
      !isSuperAdmin
        ? []
        : [
            {
              required: true,
              description: 'Should the business analyst be used?',
              name: 'USE_BUSINESS_ANALYST',
              group: 'Admin',
              key: 'USE_BUSINESS_ANALYST',
              dontEncrypt: true,
              type: 'boolean',
              default_value: 'false',
              is_internal: true,
            },

            {
              required: true,
              description: 'Should the business analyst be used?',
              name: 'USE_TECHNICAL_ANALYST',
              group: 'Admin',
              key: 'USE_TECHNICAL_ANALYST',
              dontEncrypt: true,
              type: 'boolean',
              default_value: 'false',
              is_internal: true,
            },
          ],
    [isSuperAdmin]
  )
}
