import {
  GUNSLINGER_JOB_STATUSES,
  GunslingerJobStatus,
  ITERATION_STATUSES,
  IterationComputedStatus,
  IterationStatus,
} from '@/const/const.ts'
import { SUPPORTED_LANGUAGES } from '@/const/defaults'

const VM_OVERRIDE_STATUSES = [
  GUNSLINGER_JOB_STATUSES.STOPPED,
  GUNSLINGER_JOB_STATUSES.STOPPING,
  GUNSLINGER_JOB_STATUSES.ERROR,
] as const

export function getIterationComputedStatus(
  iterationStatus: IterationStatus | null,
  vmStatus: GunslingerJobStatus | null | undefined
): IterationComputedStatus | null {
  if (vmStatus && VM_OVERRIDE_STATUSES.includes(vmStatus)) {
    if (![ITERATION_STATUSES.DONE, ITERATION_STATUSES.FAILED].includes(iterationStatus))
      return vmStatus
  }

  return iterationStatus
}

export function formatIterationStatus(status: IterationComputedStatus): string {
  if (!status) {
    return ''
  }
  return status.replace(/_/g, ' ')
}

export function generateGCPLoggingLink(filters) {
  // We do that so the project can easily change from the envs
  const gcpProjectId = import.meta.env.VITE_GCP_LOGS_PROJECT_ID
  const baseUrl = 'https://console.cloud.google.com/logs/query'
  const queryParts = Object.entries(filters).map(([key, value]) => `${key}="${value}"`)
  const query = queryParts.join(' AND ')
  const encodedQuery = encodeURIComponent(query)
  const link = `${baseUrl};query=${encodedQuery}?project=${gcpProjectId}`
  return link
}

function formatJSON(data) {
  return JSON.stringify(
    data,
    (key, value) => {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value)
        } catch (e) {
          return value
        }
      }
      return value
    },
    2
  )
}

export function prettyPrintField(field: any) {
  let content = null

  if (field == null) {
    content = null
  } else {
    if (Array.isArray(field) && field.length === 1) {
      const singleItem = field[0]
      if (singleItem) {
        content = formatJSON(singleItem)
      } else {
        content = singleItem
      }
    } else {
      content = formatJSON(field)
    }
  }

  return content
}

export function toFirestoreConsoleLink(collectionName: string, docId: string): string {
  const encodedCollection = encodeURIComponent(collectionName).replace(/%2F/gi, '~2F')
  const encodedDocId = encodeURIComponent(docId).replace(/%2F/gi, '~2F')

  const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID

  return `https://console.firebase.google.com/project/${projectId}/firestore/databases/-default-/data/~2F${encodedCollection}~2F${encodedDocId}`
}

export function isVmDead(vmStatus: typeof GUNSLINGER_JOB_STATUSES) {
  return [GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.ERROR].includes(vmStatus)
}

export function getLanguageBlock(language, content) {
  if (Object.values(SUPPORTED_LANGUAGES).includes(language)) {
    return content
  } else {
    return '```' + language + '\n' + content + '\n```'
  }
}

export function isVMreadyForPreview(vmStatus) {
  return ![GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.ERROR].includes(vmStatus)
}
