import { type MCreateProjectDialogProps } from './MCreateProjectDialog.tsx'
import MCreateProjectDialog from './MCreateProjectDialog.tsx'
import { Dialog } from '@/components/catalyst/dialog.jsx'
import { useProjectEnv } from './hooks.ts'

type MDuplicateProjectDialogProps = Omit<MCreateProjectDialogProps, 'formDefaults'> & {
  projectToDuplicate: {
    id: string

    projectConfiguration: {
      name: string
      prospectName: string
      prospectWebsite: string
      techStack: string
    }
  }
}

export default function MDuplicateProjectDialog({
  projectToDuplicate,
  ...props
}: MDuplicateProjectDialogProps) {
  const envValuesQuery = useProjectEnv(projectToDuplicate.id)

  if (envValuesQuery.isLoading) {
    return (
      <Dialog open={true} size="3xl" className="p-0" onClose={() => {}}>
        <div className="rounded-lg bg-base-muted  shadow-lg">
          <div className="grid w-full grid-cols-1 justify-items-center rounded-lg border border-base-border bg-base-card px-8 py-20 shadow-sm">
            <h3 className="mt-8 font-pp-supply-sans text-3xl leading-10 text-base-foreground">
              Loading project properties...
            </h3>
          </div>
        </div>
      </Dialog>
    )
  }
  if (envValuesQuery.isError) {
    return (
      <Dialog open={true} size="3xl" className="p-0" onClose={props.onClose}>
        <div className="rounded-lg bg-base-muted  shadow-lg">
          <div className="grid w-full grid-cols-1 justify-items-center rounded-lg border border-base-border bg-base-card px-8 py-20 shadow-sm">
            <h3 className="mt-8 font-pp-supply-sans text-3xl leading-10 text-base-foreground">
              Error loading project properties.
            </h3>
            <p className="text-sm tracking-tight text-base-muted-foreground">
              Please try again later.
            </p>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <MCreateProjectDialog
      {...props}
      formDefaults={{
        project: {
          ...projectToDuplicate.projectConfiguration,
        },
        environment: envValuesQuery.data,
        prompt: {},
      }}
    />
  )
}
