import { cn } from '@/lib/utils'
import React, { useContext, useMemo } from 'react'
import { FormErrorField } from './form-error-field.tsx'
import { Control, Controller, useController, useWatch } from 'react-hook-form'
import type { FieldValues, Path } from 'react-hook-form'
import { Input } from './input.tsx'
import { Textarea } from './textarea.tsx'
import { Checkbox } from './checkbox.tsx'

export function Root<T extends FieldValues>({
  children,
  className,
  id,
  name,
  control,
}: {
  children?: React.ReactNode
  className?: string
  id?: string
  name: Path<T>
  control: Control<T>
}) {
  const fallbackId = React.useId()

  const ctx = useMemo(
    () => ({
      id: id ?? fallbackId,
      name,
      control,
    }),
    [fallbackId, id, control, name]
  )

  return (
    <FieldContext.Provider value={ctx}>
      <div className={cn('space-y-2', className)}>{children}</div>
    </FieldContext.Provider>
  )
}

const FormInput = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof Input>>(
  (props, ref) => {
    const ctx = useContext(FieldContext)
    return <Input ref={ref} id={ctx.id} name={ctx.name} {...props} />
  }
)

FormInput.displayName = 'FormInput'

const FormTextarea = React.forwardRef<HTMLTextAreaElement, React.ComponentProps<typeof Textarea>>(
  (props, ref) => {
    const ctx = useContext(FieldContext)
    return <Textarea ref={ref} id={ctx.id} name={ctx.name} {...props} />
  }
)

FormTextarea.displayName = 'FormTextarea'

const FormCheckbox = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Checkbox>>(
  (props, ref) => {
    const ctx = useContext(FieldContext)
    return <Checkbox ref={ref} id={ctx.id} name={ctx.name} {...props} />
  }
)

FormCheckbox.displayName = 'FormCheckbox'

function FormLabel(props: React.HTMLAttributes<HTMLLabelElement>) {
  const ctx = useContext(FieldContext)
  return (
    <label
      htmlFor={ctx.id}
      {...props}
      className="text-sm font-medium leading-none text-base-foreground"
    >
      {props.children}
    </label>
  )
}

function FormError() {
  const { control, name } = useContext(FieldContext)

  const { fieldState } = useController({ control, name })

  return <FormErrorField error={fieldState.error} />
}

function FormController<T extends FieldValues>(
  props: Omit<React.ComponentProps<typeof Controller<T>>, 'name' | 'control'>
) {
  const ctx = useContext(FieldContext) as FieldContext<T>
  return <Controller {...props} control={ctx.control} name={ctx.name} />
}

// react doesn't support generics in context
const FieldContext = React.createContext<FieldContext<any>>({} as FieldContext<FieldValues>)

type FieldContext<T extends FieldValues> = {
  id: string
  name: Path<T>
  control: Control<T>
}

export default {
  Root,
  Input: FormInput,
  Checkbox: FormCheckbox,
  Textarea: FormTextarea,
  Label: FormLabel,
  Error: FormError,
  Controller: FormController,
}
