import { useToast } from '@/components/ui/use-toast.js'
import { Dialog } from '@/components/catalyst/dialog.jsx'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore'
import { ConfigurationTemplate, EnvironmentField } from './types.ts'
import { useAnalystEnvFields } from './useAnalystEnvFields.ts'
import {
  EnvironmentData,
  environmentSchema,
  ProjectData,
  projectSchema,
  PromptData,
  promptSchema,
} from './schemas.ts'
import { stackTemplate, USECASE_ID } from '@/const/stackTemplates.ts'
import {
  useConnectGithub,
  useEnvironmentFields,
  useEnvironmentValidator,
  useFetchGithubAuthorizationUrl,
  useFetchGithubInstallationUrl,
  useGithubRepos,
  useInstallGithub,
  useProjectEnv,
  useProjectForStack,
} from './hooks.ts'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import {
  Check,
  Container,
  FileText,
  MoveLeft,
  MoveRight,
  Rocket,
  SquarePen,
  Undo2,
} from 'lucide-react'
import { FolderOpen } from 'lucide-react'
import { cn } from '@/lib/utils.ts'
import { useHelpContent } from './useHelpContent.ts'
import { z } from 'zod'
import Logo from '@/assets/svg-components/Logo.jsx'
import { useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormField from '@/components/ui/form-field.tsx'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx'
import { StackSelect } from './StackSelect.tsx'
import { Button } from '@/components/ui/button.tsx'
import { SiGithub } from '@icons-pack/react-simple-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { constructPrompt } from './constructPrompt.tsx'
import { CreateProjectPayload, useCreateProject } from './useCreateProject.ts'
import { EncryptedValue } from '@/services/firebase_shared_types.ts'

type EnvironmentWithEncryption = Record<string, EncryptedValue>

export type MCreateProjectDialogProps = {
  organizationId?: string
  teamId?: string
  isOpen: boolean
  onClose: () => void
  onProjectCreated: () => void
  formDefaults: {
    project: Partial<ProjectData>
    prompt: Partial<PromptData>
    environment: EnvironmentWithEncryption
  }
}

function usePlainEnv(env: Record<string, EncryptedValue> | undefined) {
  return useMemo(
    () =>
      Object.entries(env ?? {}).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {}),
    [env]
  )
}

export default function MCreateProjectDialog({
  organizationId,
  teamId,
  isOpen,
  onClose,
  onProjectCreated = () => {},
  formDefaults,
}: MCreateProjectDialogProps) {
  const configurationTemplates = useStore(state => state.configurationTemplates)
  const adminFields = useAnalystEnvFields()

  const { toast } = useToast()

  const { response, createFn, error: creationError, isCreating, resetCreateFn } = useCreateProject()

  // pass in less
  const initialEnvironment = usePlainEnv(formDefaults.environment)

  const initialData: PartialWizardData = {
    environment: initialEnvironment,
    prompt: formDefaults.prompt,
    project: {
      organizationId,
      teamId,
      ...formDefaults.project,
    },
  }

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onProjectCreated(response?.data || {})

      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating project:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onProjectCreated])

  const onSubmit = (
    projectData: WizardData,
    configurationTemplate: ConfigurationTemplate,
    initialEnv: EnvironmentWithEncryption
  ) => {
    const configurationTemplateFields = [
      ...adminFields,
      ...(configurationTemplate?.iterationDefaultsTemplate?.environment?.fields ?? []),
    ]
    const payload: CreateProjectPayload = {
      prompt: projectData.prompt.prompt,
      organizationId: projectData.project.organizationId,
      teamId: projectData.project.teamId,
      usecaseFields: configurationTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      formData: {
        name: projectData.project.name,
        projectConfiguration: projectData.project,
        repoository: {
          githubUsername: projectData.project.githubUsername,
          repoURI: projectData.project.repoURI,
        },
        environment: processEnvironmentData(
          projectData.environment,
          configurationTemplateFields,
          initialEnv
        ),
      },
    }
    if (!isCreating) {
      createFn(payload)
    }
  }

  function processEnvironmentData(
    environmentData: EnvironmentData,
    configurationTemplateFields: EnvironmentField[],
    initialEnv: EnvironmentWithEncryption
  ) {
    const entries = Object.entries(environmentData)
    const validEntries = entries.filter(([key, value]) => value != null && value !== '')
    return validEntries
      .map(([key, value]) => {
        const field = configurationTemplateFields.find(f => f.key === key)
        // two cases:
        // 1. field has `dontEncrypt` flag -> it's not going to be encrypted
        // 2. value matches initial env: we copy the value as-is to avoid double encryption, because
        // it's coming from a past iteration and it's been already encrypted if necessary.
        const dontEncrypt = field?.dontEncrypt ?? undefined

        const existingValue = initialEnv[key]
        const copyFieldAsItIs = existingValue?.value === value

        return { ...existingValue, key, value, dontEncrypt, copyFieldAsItIs }
      })
      .reduce((acc, { key, ...field }): EnvironmentWithEncryption => ({ ...acc, [key]: field }), {})
  }

  if (configurationTemplates == null) {
    return (
      <Dialog
        className="max-h-[90vh] overflow-hidden p-[0]"
        size="3xl"
        open={isOpen}
        onClose={onClose}
      >
        <div className="rounded-lg bg-base-muted p-4 shadow-lg">
          <div className="grid w-full grid-cols-1 justify-items-center rounded-lg border border-base-border bg-base-card px-8 py-20 shadow-sm">
            <div className="rounded-full bg-gradient-to-b from-[rgba(149,78,226,0)] to-[rgba(149,78,226,0.2)] p-10">
              <Logo color="currentColor" className="h-12 w-12 animate-spin text-purple-500" />
            </div>
            <h3 className="mt-8 font-pp-supply-sans text-3xl leading-10 text-base-foreground">
              Loading initial data
            </h3>
            <p className="text-sm tracking-tight text-base-muted-foreground">
              It shouldn’t take more than a couple of minutes...
            </p>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        className="max-h-[97vh] overflow-hidden p-[0]"
        size="4xl"
        open={isOpen}
        onClose={onClose}
        static={true}
        preventOutsideClick={true}
      >
        <MCreateProjectWizard
          onCreateProject={onSubmit}
          submittingInProgress={isCreating}
          configurationTemplates={configurationTemplates}
          organizationId={organizationId}
          teamId={teamId}
          handleCancel={handleClose}
          initialData={initialData}
          initialEnv={formDefaults.environment}
        />
      </Dialog>
    </>
  )
}

type MCreateProjectWizardProps = {
  submittingInProgress: boolean
  configurationTemplates: ConfigurationTemplate[]
  initialData: PartialWizardData
  organizationId: string
  teamId: string
  initialEnv: Record<string, EncryptedValue>
  onCreateProject: (
    formData: WizardData,
    template: ConfigurationTemplate,
    initialEnv: Record<string, EncryptedValue>
  ) => void
  handleCancel: () => void
}

type WizardStep = 'project' | 'requirements' | 'environment' | 'summary'

// object of schemas, not a schema itself
const CreateProjectSchemas = {
  project: projectSchema,
  prompt: promptSchema,
  environment: environmentSchema,
}

const CreateProjectSchema = z.object(CreateProjectSchemas)

type WizardData = z.output<typeof CreateProjectSchema>

export type PartialWizardData = { [k in keyof WizardData]: Partial<WizardData[k]> | null }

const useFormValidation = (formData: PartialWizardData, template: ConfigurationTemplate | null) => {
  const fields = useEnvironmentFields(template)
  const environmentValidator = useEnvironmentValidator(fields)
  return useMemo(() => {
    const validation: { [k in keyof typeof CreateProjectSchemas]: boolean } = {
      project: CreateProjectSchemas.project.safeParse(formData.project).success,
      prompt:
        Boolean(formData.prompt) && CreateProjectSchemas.prompt.safeParse(formData.prompt).success,
      environment:
        Boolean(formData.environment) &&
        environmentValidator.safeParse(formData.environment).success,
    }
    return validation
  }, [formData, template])
}

function MCreateProjectWizard({
  onCreateProject,
  submittingInProgress,
  configurationTemplates,
  organizationId,
  teamId,
  handleCancel,
  initialData,
  initialEnv,
}: MCreateProjectWizardProps) {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const [activeStep, setActiveStep] = useState<WizardStep>('project')
  const [formData, setFormData] = useState<PartialWizardData>(() => initialData)
  useFetchGithubAuthorizationUrl() // just to trigger the fetch ahead of time
  useFetchGithubInstallationUrl()

  const selectedConfigurationTemplate = useMemo(() => {
    if (formData.project?.techStack) {
      return (
        configurationTemplates?.find(template => template.id === formData.project?.techStack) ??
        null
      )
    }
    return null
  }, [configurationTemplates, formData.project?.techStack])

  const pastProject = useProjectForStack(selectedConfigurationTemplate?.usecaseId ?? null)
  const pastProjectEnvQuery = useProjectEnv(pastProject?.id ?? null)

  const formValidation = useFormValidation(formData, selectedConfigurationTemplate)

  const content = useHelpContent(activeStep, selectedField, selectedConfigurationTemplate)

  const handleProjectSubmit = async (data: ProjectData) => {
    try {
      if (formData.project?.techStack && data.techStack !== formData.project?.techStack) {
        setFormData(prev => ({ ...prev, prompt: {}, environment: {} }))
      }
      // await new Promise(resolve => setTimeout(resolve, 500))
      const { organizationId, teamId } = data
      setFormData(prev => ({ ...prev, organizationId, teamId, project: data }))
      setActiveStep('requirements')
    } catch (error) {
      console.error('Error saving project data:', error)
    }
  }

  const handleRequirementsSubmit = async (data: PromptData) => {
    setFormData(prev => ({ ...prev, prompt: data }))
    setActiveStep('environment')
  }

  const handleEnvironmentSubmit = useCallback(async (data: EnvironmentData) => {
    try {
      setFormData(prev => ({ ...prev, environment: data }))
      setActiveStep('summary')
    } catch (error) {
      console.error('Error saving environment data:', error)
    }
  }, [])

  // inject data from past projects matching the currently chosen tech stack.
  // It might be a duplicated project, so formData.environment is
  // allowed to override pastProjectEnvQuery.data
  const combinedEnv = useMemo(() => {
    return { ...pastProjectEnvQuery.data, ...initialEnv }
  }, [initialEnv, pastProjectEnvQuery.data])

  const plainCombinedEnv = usePlainEnv(combinedEnv)

  const combinedEnvWithFormValues = useMemo(
    () => ({ ...plainCombinedEnv, ...formData.environment }),
    [formData.environment, plainCombinedEnv]
  )

  const handleBuildPoc = async () => {
    const parsedFormData = CreateProjectSchema.safeParse(formData)
    if (parsedFormData.success) {
      onCreateProject(parsedFormData.data, selectedConfigurationTemplate, combinedEnv)
    } else {
      console.error('Invalid form data:', parsedFormData.error)
    }
  }

  const handleBack = useCallback(
    (data: PromptData | EnvironmentData) => {
      switch (activeStep) {
        case 'requirements':
          setFormData(prev => ({ ...prev, prompt: data }))
          setActiveStep('project')
          break
        case 'environment':
          setFormData(prev => ({ ...prev, environment: data }))
          setActiveStep('requirements')
          break
        case 'summary':
          setActiveStep('environment')
          break
      }
    },
    [activeStep]
  )

  if (submittingInProgress) {
    return (
      <div data-testid="starting-project-splash" className="rounded-lg bg-base-muted p-4 shadow-lg">
        <div className=" grid w-full grid-cols-1 justify-items-center rounded-lg border border-base-border bg-base-card px-8 py-20 shadow-sm">
          <div className="rounded-full bg-gradient-to-b from-[rgba(149,78,226,0)] to-[rgba(149,78,226,0.2)] p-10">
            <Logo color="currentColor" className="h-12 w-12 animate-spin text-purple-500" />
          </div>
          <h3 className="mt-8 font-pp-supply-sans text-3xl leading-10 text-base-foreground">
            We’re building {formData?.project?.name} PoC
          </h3>
          <p className="text-sm tracking-tight text-base-muted-foreground">
            It shouldn’t take more than a couple of minutes...
          </p>
        </div>
      </div>
    )
  }

  return (
    <Tabs
      value={activeStep}
      className="bg-stone-100 p-4 shadow"
      orientation="vertical"
      data-testid="create-project-wizard"
      onValueChange={value => setActiveStep(value as WizardStep)}
    >
      <div className="grid grid-cols-[204px_1fr] gap-4">
        <div className="flex h-full flex-col gap-4 pb-14">
          <h3 className="font-medium text-stone-900">New Project</h3>

          <TabsList className="block h-full space-y-1">
            <ProjectWizardTab
              name="Project"
              value="project"
              activeStep={activeStep}
              isValid={formValidation.project}
              Icon={FolderOpen}
            />
            <ProjectWizardTab
              name="Requirements"
              value="requirements"
              activeStep={activeStep}
              isDisabled={!formValidation.project}
              isValid={formValidation.prompt}
              Icon={SquarePen}
            />
            <ProjectWizardTab
              name="Environment"
              value="environment"
              activeStep={activeStep}
              isDisabled={!formValidation.prompt}
              isValid={formValidation.environment}
              Icon={Container}
            />
            <ProjectWizardTab
              name="Summary"
              value="summary"
              isDisabled={!formValidation.prompt || !formValidation.environment}
              activeStep={activeStep}
              isValid={false}
              Icon={FileText}
            />
          </TabsList>
          <p className="font-['Inter'] text-sm font-normal leading-tight text-stone-500">
            {content?.text}
          </p>
        </div>
        <div className="flex-1">
          <TabsContent value="project" className="mt-0 w-full data-[state=inactive]:hidden">
            <ProjectForm
              onSubmit={handleProjectSubmit}
              initialData={formData.project}
              onSelectField={field => {
                setSelectedField(field)
              }}
              organizationId={organizationId}
              teamId={teamId}
              handleCancel={handleCancel}
            />
          </TabsContent>
          <TabsContent value="requirements" className="mt-0 w-full data-[state=inactive]:hidden">
            <RequirementsForm
              onSubmit={handleRequirementsSubmit}
              initialData={formData.prompt ?? {}}
              onSelectField={setSelectedField}
              handleCancel={handleCancel}
              handleBack={handleBack}
              basePrompt={constructPrompt(formData.project)}
            />
          </TabsContent>
          <TabsContent value="environment" className="mt-0 w-full data-[state=inactive]:hidden">
            <EnvironmentForm
              onSubmit={handleEnvironmentSubmit}
              onBack={handleBack}
              key={formData.project?.techStack}
              initialData={combinedEnvWithFormValues}
              onSelectField={setSelectedField}
              selectedConfigurationTemplate={selectedConfigurationTemplate}
              handleCancel={handleCancel}
            />
          </TabsContent>
          <TabsContent value="summary" className="mt-0 w-full data-[state=inactive]:hidden">
            <SummaryForm
              onSubmit={handleBuildPoc}
              onBack={handleBack}
              formData={formData}
              submittingInProgress={submittingInProgress}
              handleCancel={handleCancel}
            />
          </TabsContent>
        </div>
      </div>
    </Tabs>
  )
}

export type SummaryFormProps = {
  onSubmit: () => void
  onBack: () => void
  formData: PartialWizardData
  submittingInProgress: boolean
  handleCancel: () => void
}

function SummaryForm({
  onSubmit,
  onBack,
  formData,
  submittingInProgress,
  handleCancel,
}: SummaryFormProps) {
  const isSuperAdmin = useIsUserSuperAdmin()

  const StackIcon = stackTemplate[formData.project.techStack]?.icon
  const isCustom = formData.project.techStack === USECASE_ID.CUSTOM

  return (
    <div className="flex flex-col gap-4">
      <Content
        className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-200/60 hover:scrollbar-thumb-gray-300/60 
                    flex flex-col gap-8 overflow-y-auto"
      >
        <h2 className="text-lg font-medium leading-7 text-base-foreground">
          {isCustom ? 'Project summary' : `Project for ${formData.project.prospectName}.`}
        </h2>

        <dl className="grid w-full grid-cols-[128px,1fr] text-sm leading-tight *:border-t *:border-base-border *:py-3">
          {!isCustom && (
            <>
              <dt className="font-medium text-muted-foreground">Prospect:</dt>
              <dd>{formData.project.prospectName}</dd>

              <dt className="font-medium text-muted-foreground">Website:</dt>
              <dd>{formData.project.prospectWebsite}</dd>
            </>
          )}

          <dt className="border-b font-medium text-muted-foreground">Date:</dt>
          <dd className="border-b">
            {new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </dd>
        </dl>

        <div className="flex flex-col items-start justify-start gap-4 self-stretch font-['Inter'] text-base font-normal text-stone-900">
          <div className="flex h-16 flex-col items-start justify-start gap-4 self-stretch">
            <div className="text-base-foreground">Technology</div>
            <p>
              <span className="inline-flex items-baseline gap-1 rounded-md bg-base-muted px-1.5">
                {StackIcon && <StackIcon className="size-4 self-center" />}
                {stackTemplate[formData.project.techStack]?.label}
              </span>{' '}
              {isCustom ? 'open-ended project.' : 'as main e-commerce system.'}
            </p>
          </div>
          <div className="font-medium">Objective</div>
          <div className="whitespace-pre-wrap ">{formData.prompt?.prompt}</div>
        </div>
      </Content>
      <div className="flex items-center gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button variant="ghost" onClick={onBack} className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button onClick={onSubmit} className="w-full" disabled={submittingInProgress}>
          {submittingInProgress ? (
            'Building PoC...'
          ) : (
            <>
              Build <Rocket />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

function ProjectWizardTab({
  name,
  value,
  activeStep,
  isDisabled,
  isValid,
  Icon,
}: {
  name: string
  value: string
  activeStep: string
  isValid: boolean
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  isDisabled?: boolean
}) {
  const selected = activeStep === value
  return (
    <TabsTrigger
      value={value}
      disabled={isDisabled}
      className={cn('h-10 w-full items-center justify-start gap-3', 'px-4 py-2.5')}
    >
      <Icon className={cn('size-4', selected ? 'text-orange-500' : 'text-stone-500')} />
      <span>{name}</span>
      {isValid && !isDisabled && <Check className="ml-auto size-4" />}
    </TabsTrigger>
  )
}

const RequirementsForm: React.FC<{
  onSubmit: (data: PromptData) => void
  basePrompt: string // constructed from project data
  initialData: PromptData
  onSelectField: (field: string) => void
  handleCancel: () => void
  handleBack: (data: PromptData) => void
}> = ({ onSubmit, initialData, onSelectField, handleCancel, handleBack, basePrompt }) => {
  const initialRequirements = initialData?.prompt ?? basePrompt
  const form = useForm<PromptData>({
    resolver: zodResolver(promptSchema),
    defaultValues: {
      prompt: initialRequirements,
    },
  })

  const prompt = form.watch('prompt')

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Content>
        <h2 className="text-lg font-medium text-base-foreground">What do you want to build?</h2>
        <p className="mb-8 mt-2 text-sm text-muted-foreground">
          Provide details about what do you want to build.
        </p>

        <FormField.Root name="prompt" control={form.control}>
          <div className="flex items-center justify-between">
            <FormField.Label>Requirements</FormField.Label>

            <Button
              variant="ghost"
              onClick={() => form.setValue('prompt', basePrompt)}
              className={cn(prompt === basePrompt && 'invisible')}
              type="button"
            >
              <Undo2 /> Revert
            </Button>
          </div>
          <FormField.Textarea
            {...form.register('prompt')}
            placeholder="I want to build a website that..."
            rows={10}
          />
          <FormField.Error />
        </FormField.Root>
      </Content>
      <div className="flex items-center justify-between gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button onClick={() => handleBack(form.getValues())} variant="ghost" className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button type="submit" disabled={!form.formState.isValid} className="min-w-24">
          Next <MoveRight />
        </Button>
      </div>
    </form>
  )
}

export type EnvironmentFormProps = {
  onSubmit: (data: EnvironmentData) => void
  onBack: () => void
  initialData?: Partial<EnvironmentData>
  selectedConfigurationTemplate: ConfigurationTemplate | null
  onSelectField?: (fieldName: keyof EnvironmentData) => void
  handleCancel: () => void
}

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  selectedConfigurationTemplate,
  handleCancel,
}) => {
  const fields = useEnvironmentFields(selectedConfigurationTemplate)
  const validator = useEnvironmentValidator(fields)

  const defaultValues = useMemo((): Record<string, string | boolean> => {
    return fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]:
          initialData && initialData[field.key]
            ? initialData[field.key]
            : (field.default_value ?? null),
      }),
      {}
    )
  }, [fields, initialData])

  const form = useForm({
    resolver: zodResolver(validator),
    defaultValues: defaultValues,
    mode: 'all',
  })

  const isValid = form.formState.isValid

  useLayoutEffect(() => {
    if (fields[0]) {
      form.setFocus(fields[0].key)
    }
    form.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Content className="max-h-[576px]">
        <h2 className="mb-6 text-lg font-medium text-base-foreground">Environment setup</h2>
        {(!fields || fields?.length === 0) && (
          <div>No environment setup required for this stack.</div>
        )}

        {fields.map(field => {
          return (
            <FormField.Root
              key={field.key}
              name={field.key}
              control={form.control}
              className={cn(
                'mt-2',
                field.type === 'boolean' && 'flex flex-row-reverse items-end justify-end gap-2'
              )}
            >
              <FormField.Label>
                {field.key}
                {field.required && <span className="text-destructive"> *</span>}
              </FormField.Label>
              {field.type === 'text-long' ? (
                <FormField.Textarea
                  {...form.register(field.key)}
                  onFocus={() => onSelectField?.(field.key)}
                />
              ) : field.type === 'boolean' ? (
                <FormField.Checkbox
                  {...form.register(field.key)}
                  onFocus={() => onSelectField?.(field.key)}
                  onCheckedChange={checked => form.setValue(field.key, checked.toString())}
                />
              ) : (
                <FormField.Input
                  {...form.register(field.key)}
                  className={cn(
                    !field.dontEncrypt && 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                />
              )}
              <FormField.Error />
            </FormField.Root>
          )
        })}
      </Content>

      <div className="flex items-center gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button variant="ghost" onClick={() => onBack(form.getValues())} className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button type="submit" disabled={!isValid} className="min-w-24">
          Next <MoveRight />
        </Button>
      </div>
    </form>
  )
}

const ProjectForm: React.FC<{
  onSubmit: (data: ProjectData) => void
  initialData: ProjectData
  onSelectField: (field: string) => void
  organizationId: string
  teamId: string
  handleCancel: () => void
}> = ({ onSubmit, initialData, onSelectField, organizationId, teamId, handleCancel }) => {
  const organizationsOptions = useStore(state =>
    state.organizations
      .filter(org => !org.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedOrganizationId =
    initialData?.organizationId ??
    organizationId ??
    (organizationsOptions.length === 1 ? organizationsOptions[0].value : '')

  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)

  const initialTeamOptions = getTeamsByOrgId(initialSelectedOrganizationId)
    .filter(team => !team.is_reference)
    .map(org => ({ value: org.id, label: org.name }))

  const initialSelectedTeamId =
    initialData?.teamId ??
    teamId ??
    (initialTeamOptions.length === 1 ? initialTeamOptions[0].value : '')

  const form = useForm<ProjectData>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: initialData?.name,
      prospectName: initialData?.prospectName,
      prospectWebsite: initialData?.prospectWebsite,
      techStack: initialData?.techStack,
      organizationId: initialSelectedOrganizationId,
      teamId: initialSelectedTeamId,
      githubUsername: initialData?.githubUsername,
      repoURI: initialData?.repoURI,
    },
    mode: 'onChange',
  })

  const techStack = form.watch('techStack')
  const selectedOrganizationId = form.watch('organizationId')
  const teamOptions = useMemo(() => {
    return getTeamsByOrgId(selectedOrganizationId)
      .filter(team => !team.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  }, [selectedOrganizationId, getTeamsByOrgId])

  const ecommerceSelected = techStack && techStack !== USECASE_ID.CUSTOM

  const showOrgAndTeamFields = organizationsOptions.length > 1 || teamOptions.length > 1

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Content className="space-y-6">
        <h2 className="text-lg font-medium text-base-foreground">Project details</h2>

        <FormField.Root name="name" control={form.control}>
          <FormField.Label>Project</FormField.Label>
          <FormField.Input {...form.register('name')} placeholder="Project name" />
          <FormField.Error />
        </FormField.Root>

        {showOrgAndTeamFields && (
          <>
            <FormField.Root name="organizationId" control={form.control}>
              <FormField.Label>Organization</FormField.Label>
              <FormField.Controller
                render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger onFocus={() => onSelectField('organizationId')}>
                      <SelectValue placeholder="Select an organization..." />
                    </SelectTrigger>
                    <SelectContent>
                      {organizationsOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              <FormField.Error />
            </FormField.Root>
            <FormField.Root name="teamId" control={form.control}>
              <FormField.Label>Team</FormField.Label>
              <FormField.Controller
                render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger onFocus={() => onSelectField('teamId')}>
                      <SelectValue placeholder="Select a team..." />
                    </SelectTrigger>
                    <SelectContent>
                      {teamOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              <FormField.Error />
            </FormField.Root>
          </>
        )}

        <FormField.Root name="techStack" control={form.control}>
          <FormField.Label>Technology</FormField.Label>
          <FormField.Controller
            render={({ field }) => (
              <StackSelect
                defaultValue={field.value}
                onValueChange={field.onChange}
                onFocus={() => onSelectField('techStack')}
              />
            )}
          />
          <FormField.Error />
        </FormField.Root>

        {ecommerceSelected && (
          <>
            <FormField.Root name="prospectName" control={form.control}>
              <FormField.Label>Prospect</FormField.Label>
              <FormField.Input
                {...form.register('prospectName')}
                placeholder=""
                onFocus={() => onSelectField('prospectName')}
              />
              <FormField.Error />
            </FormField.Root>

            <FormField.Root name="prospectWebsite" control={form.control}>
              <FormField.Label>Prospect Website</FormField.Label>
              <FormField.Input
                {...form.register('prospectWebsite')}
                placeholder=""
                onFocus={() => onSelectField('prospectWebsite')}
              />
              <FormField.Error />
            </FormField.Root>
          </>
        )}
        <RepositoryFormFields onSelectField={onSelectField} form={form} />
      </Content>
      <div className="flex items-center justify-between gap-2">
        <Button onClick={handleCancel} variant="outline" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button type="submit" disabled={!form.formState.isValid} className="min-w-24">
          Next <MoveRight />
        </Button>
      </div>
    </form>
  )
}

const RepositoryFormFields: React.FC<{
  onSelectField: (field: string) => void
  form: UseFormReturn<ProjectData>
}> = ({ onSelectField, form }) => {
  const user = useStore(state => state.user)
  const hasGithub = Boolean(user.github && Object.keys(user.github).length > 0)

  const [isConnected, setIsConnected] = useState(hasGithub)

  const onCancel = () => {
    setIsConnected(false)
    form.setValue('githubUsername', undefined)
    form.setValue('repoURI', undefined)
  }

  const connectGithub = useConnectGithub()
  const installGithub = useInstallGithub()

  return (
    <>
      {!isConnected && (
        <RepositoryConnectionChoiceMethod
          onSelect={setIsConnected}
          hasGithub={hasGithub}
          connectGithub={connectGithub}
        />
      )}
      {isConnected && !hasGithub && <AwaitingGithubConnection onCancel={onCancel} />}
      {isConnected && hasGithub && (
        <RepositoryGithubForm
          form={form}
          onSelectField={onSelectField}
          onReauth={connectGithub}
          onInstall={installGithub}
        />
      )}
    </>
  )
}

const AwaitingGithubConnection: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  return (
    <div className="mx-auto text-center">
      <h3 className="font-medium">Authorizing with GitHub...</h3>
      <p className="mb-2 mt-4 text-center text-muted-foreground">
        Come back to this page once the GitHub application
        <br />
        has been authorized. It will refresh automatically.
      </p>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

const RepositoryGithubForm: React.FC<{
  form: UseFormReturn<ProjectData>
  onSelectField: (field: string) => void
  onReauth: () => void
  onInstall: () => void
}> = ({ form, onSelectField, onReauth, onInstall }) => {
  const user = useStore(state => state.user)
  const logins = Object.keys(user.github)

  const selectedLogin = useWatch({
    control: form.control,
    name: 'githubUsername',
  })

  useEffect(() => {
    const logins = Object.keys(user.github)
    if (!selectedLogin) {
      form.setValue('githubUsername', logins[0])
    }
  }, [selectedLogin, user.github, form.setValue])

  const repos = useGithubRepos(selectedLogin)

  return (
    <>
      <FormField.Root name="githubUsername" control={form.control}>
        <FormField.Label>GitHub account</FormField.Label>
        <FormField.Controller
          render={({ field }) => (
            <DropdownMenu onOpenChange={() => onSelectField('githubAccount')}>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-full content-start !justify-start">
                  <div className="flex items-center justify-start gap-3">
                    <div className="inline-flex gap-3 font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                      <SiGithub className="size-4" />
                      <span>
                        @{selectedLogin}
                        <span className="font-normal"> account connected</span>
                      </span>
                    </div>
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="mt-1 w-[var(--radix-dropdown-menu-trigger-width)] rounded border border-border bg-white p-2 shadow-lg"
                align="start"
              >
                {logins
                  .filter(login => login !== selectedLogin)
                  .map(login => (
                    <DropdownMenuItem key={login} onSelect={() => field.onChange}>
                      Select @{login}
                    </DropdownMenuItem>
                  ))}
                <DropdownMenuItem onSelect={onReauth}>Add GitHub account</DropdownMenuItem>
                <DropdownMenuItem onSelect={onInstall}>Authorize repositories</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        />
      </FormField.Root>
      <FormField.Root name="repoURI" control={form.control}>
        <FormField.Label>
          Pick repository
          <span className="ml-2 font-normal text-muted-foreground">
            {' '}
            Not seeing your repositories?{' '}
            <button
              id="check-permissions-btn"
              className="underline"
              onClick={onInstall}
              type="button"
            >
              Check permissions
            </button>
          </span>
        </FormField.Label>
        <FormField.Controller
          render={({ field }) => (
            <Select
              onOpenChange={open => open && repos.refetch()}
              onValueChange={field.onChange}
              value={field.value}
              onFocus={() => onSelectField('repoURI')}
            >
              <SelectTrigger
                id="repositories"
                onClick={e => e.stopPropagation()}
                onFocus={() => onSelectField('repoURI')}
                tabIndex={0}
                className="mt-1"
              >
                <SelectValue placeholder="Select a repository..." />
              </SelectTrigger>
              <SelectContent className="w-full" onFocus={() => onSelectField('repoURI')}>
                {repos.isLoading && <div className="px-4 py-2 text-center text-sm">Loading...</div>}
                {repos.isError && (
                  <div className="px-4 py-2 text-center text-sm">
                    An error occured. Please refresh the page.
                  </div>
                )}
                {repos.isSuccess && repos.data.length === 0 && (
                  <div className="px-4 py-2 text-center text-sm">No repositories found.</div>
                )}
                {repos.isSuccess &&
                  repos.data.map(option => {
                    return (
                      <SelectItem key={option.clone_url} value={option.clone_url}>
                        <div className="flex items-center gap-3">
                          <img className="size-4 rounded-full" src={option.owner.avatar_url} />
                          <span className="text-sm font-medium leading-tight text-stone-900">
                            {option.full_name}
                          </span>
                        </div>
                      </SelectItem>
                    )
                  })}
              </SelectContent>
            </Select>
          )}
        />
      </FormField.Root>
    </>
  )
}

const RepositoryConnectionChoiceMethod: React.FC<{
  hasGithub: boolean
  connectGithub: () => void
  onSelect: (value: boolean) => void
}> = ({ hasGithub, onSelect, connectGithub }) => {
  const urlResult = useFetchGithubAuthorizationUrl()

  function onConnectGithub() {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    if (!hasGithub) {
      connectGithub()
    }
    onSelect(true)
  }

  return (
    <div className="mx-auto flex max-w-sm flex-col p-4 text-center">
      <p className="mb-4 mt-2 grow tracking-tight text-muted-foreground">
        Connect with all or selected GitHub repositories. Proofs automatically gets all details.
      </p>
      <Button disabled={!urlResult.isSuccess} onClick={onConnectGithub}>
        <SiGithub className="mr-2 size-4" />
        <span>{hasGithub ? 'Continue with GitHub' : 'Connect GitHub'}</span>
      </Button>
    </div>
  )
}

const Content = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={cn(
        'border border-border',
        'bg-white',
        'rounded-lg',
        'shadow',
        'p-8',
        'mb-4',
        'overflow-y-auto',
        'max-h-[80vh]',
        className
      )}
    >
      {children}
    </div>
  )
}
