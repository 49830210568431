import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx'
import { stackTemplate, technologiesOptions } from '@/const/stackTemplates.ts'
import _ from 'lodash'

export function StackSelect(props: {
  onValueChange: (value: string) => void
  defaultValue: string
  onFocus: () => void
}) {
  const groups = _.groupBy(technologiesOptions, 'group')

  return (
    <Select onValueChange={props.onValueChange} defaultValue={props.defaultValue}>
      <SelectTrigger
        id="techStack"
        onFocus={props.onFocus}
        tabIndex={0}
        className="my-custom-focus mt-1"
      >
        <SelectValue placeholder="Select a stack..." />
      </SelectTrigger>
      <SelectContent className="w-full" onFocus={props.onFocus}>
        {technologiesOptions.map(option => {
          const stack = option.value
          const TechnologyIcon = stackTemplate[stack]?.icon
          return (
            <SelectItem key={option.value} value={option.value} disabled={option.disabled}>
              <div className="flex items-center gap-3">
                {TechnologyIcon && <TechnologyIcon className="size-4" />}
                {option.label}
              </div>
            </SelectItem>
          )
        })}
      </SelectContent>
    </Select>
  )
}
