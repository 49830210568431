/**
 * Tries to detect the programming language of a file from its file extension (e.g. .py, .js, .ts)
 * @param {string} filePath
 * @returns {string} the name of programming language detected
 */
export function detectProgrammingLanguageFromFilePath(filePath) {
  const fileExt = filePath?.split('.').pop()
  let language
  switch (fileExt) {
    case 'py':
      language = 'python'
      break
    case 'js':
      language = 'javascri[t'
      break
    case 'jsx':
      language = 'jsx'
      break
    case 'ts':
      language = 'typescript'
      break
    case 'tsx':
      language = 'tsx'
      break
    case 'html':
      language = 'html'
      break
    case 'css':
      language = 'css'
      break
    case 'yml':
      language = 'yaml'
      break
    case 'md':
      language = 'markdown'
      break
    default:
      language = 'python'
  }
  return language
}

/**
 * Takes a string and inserts newlines every 5 words if there are no newlines already present
 * @param {string} str
 * @returns {string}
 */
export function insertNewlinesIfNeeded(str) {
  if (typeof str !== 'string') {
    return str
  }

  if (!str) {
    return ''
  }
  const howManyWordsPerLine = 10
  // Check if the string already contains newlines
  if (str.includes('\n')) {
    return str // No modification needed, return the original string
  }

  // Split the string into an array of words
  const words = str?.split(' ')

  // Iterate through the words, inserting newlines every 5 words
  let result = ''
  for (let i = 0; i < words.length; i++) {
    result += words[i] + ' '

    // Insert a newline if we're at a multiple of 5 (and not the first word)
    if ((i + 1) % howManyWordsPerLine === 0 && i !== 0) {
      result += '\n'
    }
  }

  return result
}
