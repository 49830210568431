import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import CodeMirror from '@uiw/react-codemirror'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { languages } from '@codemirror/language-data'
import { python } from '@codemirror/lang-python'
import { javascript } from '@codemirror/lang-javascript'
import { json } from '@codemirror/lang-json'
import { yaml } from '@codemirror/lang-yaml'
import { css } from '@codemirror/lang-css'
import { EditorView } from '@codemirror/view'
import { materialDark } from '@uiw/codemirror-theme-material'
import { SUPPORTED_LANGUAGES } from '@/const/defaults.js'

function getExtenstionsForLanguageCode(landuageCode) {
  switch (landuageCode) {
    case SUPPORTED_LANGUAGES.JSON:
      return [json(), EditorView.lineWrapping]
    case SUPPORTED_LANGUAGES.PYTHON:
      return [python()]
    case SUPPORTED_LANGUAGES.JAVASCRIPT:
      return [javascript(), EditorView.lineWrapping]
    case SUPPORTED_LANGUAGES.TYPESCRIPT:
      return [javascript({ typescript: true, jsx: true }), EditorView.lineWrapping]
    case SUPPORTED_LANGUAGES.YAML:
      return [yaml()]
    case SUPPORTED_LANGUAGES.CSS:
      return [css()]
    case SUPPORTED_LANGUAGES.JSX:
      return [javascript({ jsx: true }), EditorView.lineWrapping]
    case SUPPORTED_LANGUAGES.TSX:
      return [javascript({ typescript: true, jsx: true }), EditorView.lineWrapping]
    default:
      return [
        markdown({ base: markdownLanguage, codeLanguages: languages }),
        EditorView.lineWrapping,
      ]
  }
}

/**
 * Code Preview element, supports languages Markdown and JSON
 * @param {object} props - The props object
 * @param {string | object} props.code - code in json or text format
 * @param {string} props.language - language of the code, default is markdown
 * @param {boolean} [props.editable] - If the editor should allow editing of the code
 * @param {function} props.onCodeChange - Callback function to be called when the code is changed
 * @param {function} props.onEditorDidMount - Callback function to be called when the editor is created
 * @returns {JSX.Element | null}
 */
export default function OCodeEditor({
  code = null,
  language = 'markdown',
  editable = false,
  onCodeChange = () => {
    console.log('DEFAULT onCodeChange called from OCodeEditor')
  },
  onEditorDidMount = () => {},
}) {
  let convertedCode = useMemo(() => {
    if (!code) {
      return ''
    } else if (typeof code === 'object') {
      return JSON.stringify(code, null, 2)
    } else {
      return code
    }
  }, [code])

  const extensions = getExtenstionsForLanguageCode(language)

  const handleOnChange = useCallback(
    value => {
      onCodeChange(value)
    },
    [onCodeChange]
  )

  return (
    <div className="overflow-clip whitespace-normal">
      <CodeMirror
        theme={materialDark}
        editable={editable}
        value={convertedCode}
        extensions={extensions}
        onChange={handleOnChange}
        onCreateEditor={view => {
          // Call onEditorDidMount when the editor is created
          onEditorDidMount(view)
        }}
      />
    </div>
  )
}

OCodeEditor.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  language: PropTypes.string,
  editable: PropTypes.bool,
  onCodeChange: PropTypes.func,
  onEditorDidMount: PropTypes.func,
}
