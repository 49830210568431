import { USECASE_ID } from '@/const/stackTemplates.ts'
import { z } from 'zod'

export const repositorySchema = z
  .object({
    repoURI: z
      .string()
      .optional()
      .refine(
        url => !url || isValidGitHubUrl(url),
        'Must be a valid GitHub repository URL (HTTPS or SSH format)'
      ),
    startCommitHash: z
      .string()
      .optional()
      .refine(
        val => !val || /^[a-f0-9]{40}$|^[a-f0-9]{7}$/.test(val),
        'Invalid commit hash format'
      ),
    githubUsername: z.string().optional(),
  })
  .refine(
    data => {
      return Boolean(data.githubUsername && data.repoURI)
    },
    {
      message: 'Connect to GitHub and select a repository',
    }
  )

export type RepositoryData = z.infer<typeof repositorySchema>
// Custom validation for GitHub repository URLs

const isValidGitHubUrl = (url: string) => {
  // Match HTTPS format: https://github.com/org/repo.git
  const httpsPattern = /^https:\/\/github\.com\/[\w-]+\/[\w.-]+(?:\.git)?$/

  // Match SSH format: git@github.com:org/repo.git
  const sshPattern = /^git@github\.com:[\w-]+\/[\w.-]+(?:\.git)?$/

  return httpsPattern.test(url) || sshPattern.test(url)
}
export const projectSchema = z
  .object({
    name: z.string().min(1, 'Project name is required'),
    prospectName: z.string().nullish(),
    prospectWebsite: z.string().nullish(),
    repoURI: z
      .string()
      .refine(
        url => isValidGitHubUrl(url),
        'Must be a valid GitHub repository URL (HTTPS or SSH format)'
      ),
    githubUsername: z.string(),
    techStack: z.string().min(1, 'Tech stack is required'),
    organizationId: z.string().min(1, 'Organization is required'),
    teamId: z.string().min(1, 'Team is required'),
  })
  .refine(
    data => {
      if (data.techStack === USECASE_ID.CUSTOM) {
        return true
      }
      return Boolean(data.prospectName)
    },
    {
      message: 'Prospect name is required',
      path: ['prospectName'],
    }
  )
  .refine(
    data => {
      if (data.techStack === USECASE_ID.CUSTOM) {
        return true
      }
      const value = data.prospectWebsite ?? ''
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i
      // If URL has a protocol, validate the complete URL
      if (value.startsWith('http://') || value.startsWith('https://')) {
        return urlRegex.test(value)
      }

      // For protocol-less URLs, test both the original and with added https://
      return urlRegex.test(value) || urlRegex.test(`https://${value}`)
    },
    {
      message: 'Prospect website is required',
      path: ['prospectWebsite'],
    }
  )

export const promptSchema = z.object({
  prompt: z.string().min(1, 'Prompt is required'),
})

export const environmentSchema = z.record(z.string(), z.string())

export type ProjectData = z.infer<typeof projectSchema>
export type PromptData = z.infer<typeof promptSchema>
export type EnvironmentData = z.infer<typeof environmentSchema>
